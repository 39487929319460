import { ForgotPassword, Login } from "../pages/Login";
import { routeName } from "./route-name";
// import { ComponentClass, FunctionComponent } from "react";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { Symbol } from "../pages/Symbol";
import { TopVolDiff } from "../pages/TopVolDiff/TopVolDiff";
import { Fibo } from "../pages/Fibo/Fibo";
import { Exchange } from "../pages/Exchange/Exchange";

export interface RouteConfig {
  path: string;
  component: any;
  name: string;
}

export const authRoutes: RouteConfig[] = [
  {
    path: routeName.login,
    component: Login,
    name: "",
  },
  {
    path: routeName.forgotPassword,
    component: ForgotPassword,
    name: "",
  },
];

export const mainRoutes: RouteConfig[] = [
  // {
  //   path: routeName.dashboard,
  //   component: Dashboard,
  //   name: "Volume",
  // },
  {
    path: routeName.exchange,
    component: Exchange,
    name: "Exchange",
  },
  {
    path: routeName.topVolDiff,
    component: TopVolDiff,
    name: "Volume change table",
  },
  {
    path: routeName.fibonacci,
    component: Fibo,
    name: "Fibonacci",
  },
  {
    path: routeName.notification,
    component: Symbol,
    name: "Symbol",
  },
];

export const subRoutes: RouteConfig[] = [];
